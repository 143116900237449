import React, { useState } from "react";
import "./StatusSettings.scss";
import Select from "react-select";
import {
  CriterionParameter,
  DataType,
  FunctionDTO,
  FunctionStatus,
  StatusCriterionDTO,
} from "../../../../api/auth/apiClient";
import { mapClient } from "../../../../api/auth/AxiosInstanse";
import { customStyles, optionsValue } from "./options";
import { ReactComponent as IconAddCriteria } from "./svg/AddCriteria.svg";
import { ReactComponent as TrashCan } from "./svg/trash-can.svg";
import { NumericFormat } from "react-number-format";
import { FormattedMessage, useIntl } from "react-intl";
import { Controller, useFieldArray, UseFormReturn } from "react-hook-form";
import { Expander, ExpanderItem } from "@aws-amplify/ui-react";

type StatusSettingsTypeProps = {
  statusFunctions: Array<FunctionStatus>;
  criteriaParameters: Array<CriterionParameter>;
  selectedRow: FunctionDTO;
  setSelectedRow: (row: FunctionDTO) => void;
  isDisabledFunction: boolean;
  dataType: DataType;
  formMethods: UseFormReturn<FunctionDTO>;
};
function StatusSettings(props: StatusSettingsTypeProps) {
  const intl = useIntl();
  const control = props.formMethods.control;
  const { fields, append, remove } = useFieldArray({
    control,
    name: "statusCriteria",
  });
  const [selectedStatus, setSelectedStatus] = useState<{
    value: number | undefined;
    label: string | undefined;
  }>(
    props.statusFunctions
      .filter((s) => s.id !== 3)
      .map((s) => {
        return { value: s.id, label: s.name };
      })
      .find(
        (s) =>
          s.value ===
          (props.selectedRow.statusCriteria ?? []).find(
            (x) => x.statusId !== undefined
          )?.statusId
      ) ??
      props.statusFunctions.map((s) => {
        return { value: s.id, label: s.name };
      })[0]
  );
  const [idNewCriteria, setIdNewCriteria] = useState(-1);
  function addNewCriteria() {
    const newValue = new StatusCriterionDTO({
      id: idNewCriteria.toString(),
      uvi: props.selectedRow.uvi,
      functionId: props.selectedRow.id,
      parameterId: props.criteriaParameters[0].id!,
      statusId: selectedStatus?.value!,
      value: 0,
    });
    append(newValue);
    setIdNewCriteria(idNewCriteria - 1);
  }
  async function deleteCriteria(id: string, index: number) {
    if (id.length === 36) {
      try {
        await mapClient.deleteStatusCriterion(id);
      } catch {}
    }
    remove(index);
  }
  return (
    <Expander
      type="multiple"
      defaultValue={props.selectedRow.status?.id !== 3 ? ["item-1"] : []}
    >
      <ExpanderItem
        title={intl.formatMessage({ id: "status_settings_title" })}
        value="item-1"
      >
        <div className="settingsContainer">
          {/*<div>
              <div className="settingsTitle">
                <h3>
                  <FormattedMessage id="status_settings_title" />
                </h3>
              </div>
            </div>*/}
          <div className="settingsBlockContainer">
            <div className="statusItem">
              <div className="mb-2">
                <span>
                  <FormattedMessage id="status_settings_choice" />
                </span>
              </div>
              <div>
                <Select
                  options={props.statusFunctions
                    .filter((s) => s.id !== 3)
                    .map((s) => {
                      return { value: s.id, label: s.name };
                    })}
                  onChange={(value) => setSelectedStatus(value!)}
                  value={selectedStatus}
                  isDisabled={props.isDisabledFunction}
                  styles={customStyles}
                />
              </div>
            </div>
            <div className="criteria">
              <div>
                <div className="criteriaHeader ">
                  <div>
                    <span>
                      <FormattedMessage id="criteria_params_title" />
                    </span>
                  </div>
                </div>
              </div>
              <div className="criteriaParams">
                {fields
                  ?.filter((s) => s.statusId === selectedStatus?.value)
                  .map((s, index) => (
                    <div className="params" key={s.id}>
                      <Controller
                        name={`statusCriteria.${index}.parameterId`}
                        control={control}
                        rules={{
                          validate: (value) =>
                            props.dataType === DataType._1
                              ? value === 1 || value === 4
                                ? true
                                : intl.formatMessage({
                                    id: "validate_parameterId",
                                  })
                              : true,
                        }}
                        render={({
                          field: { value, onChange, name },
                          fieldState: { error },
                        }) => (
                          <>
                            <Select
                              name={name}
                              isSearchable={false}
                              isClearable={false}
                              options={props.criteriaParameters
                                .filter((c) =>
                                  props.dataType === DataType._1
                                    ? c.id === 1 || c.id === 4
                                    : true
                                )
                                .map((c) => {
                                  return { value: c.id, label: c.name };
                                })}
                              value={props.criteriaParameters
                                .map((c) => {
                                  return { value: c.id, label: c.name };
                                })
                                .find((c) => c.value === value)}
                              placeholder={intl.formatMessage({
                                id: "placeholder_criteria_params",
                              })}
                              onChange={(value) => {
                                onChange(value?.value!);
                              }}
                              isDisabled={props.isDisabledFunction}
                              styles={customStyles}
                            />
                            <div className="mb-2.5">
                              {error && <p>{error.message || "Errors!!!"}</p>}
                            </div>
                          </>
                        )}
                      />
                    </div>
                  ))}
              </div>
              <div className="btnAddCriteria">
                <button
                  className={`${
                    props.isDisabledFunction ||
                    (props.dataType === DataType._1 && fields.length > 0)
                      ? "disabled"
                      : "active"
                  }`}
                  onClick={() => {
                    addNewCriteria();
                  }}
                  disabled={
                    props.isDisabledFunction ||
                    (props.dataType === DataType._1 && fields.length > 0)
                  }
                  type="button"
                >
                  <div className="addCriteriaContainer">
                    <div>
                      <IconAddCriteria />
                    </div>
                    <div
                      className={`ml-5 ${
                        props.isDisabledFunction ||
                        (props.dataType === DataType._1 && fields.length > 0)
                          ? "disabled"
                          : "active"
                      }`}
                    >
                      <span>
                        <FormattedMessage id="add_criteria" />
                      </span>
                    </div>
                  </div>
                </button>
              </div>
            </div>
            <div className="criteriaValue">
              <div className="valueHeader">
                <span>
                  <FormattedMessage id="value_title" />
                </span>
              </div>
              <div className="valueBody">
                {fields
                  ?.filter((s) => s.statusId === selectedStatus?.value)
                  .map((v, index) => (
                    <div className="value" key={v.id}>
                      {props.dataType === DataType._1 ? (
                        <div className="value_select">
                          <Controller
                            control={control}
                            name={`statusCriteria.${index}.value`}
                            rules={{
                              validate: (value) =>
                                props.dataType === DataType._1
                                  ? value === 0 || value === 1
                                    ? true
                                    : intl.formatMessage({
                                        id: "validate_value",
                                      })
                                  : true,
                            }}
                            render={({
                              field: { value, onChange, name },
                              fieldState: { error },
                            }) => (
                              <>
                                <Select
                                  name={name}
                                  options={optionsValue}
                                  styles={customStyles}
                                  isDisabled={props.isDisabledFunction}
                                  isSearchable={false}
                                  isClearable={false}
                                  placeholder={intl.formatMessage({
                                    id: "placeholder_value",
                                  })}
                                  value={{
                                    value: value,
                                    label: value?.toString(),
                                  }}
                                  onChange={(value) => {
                                    onChange(value?.value!);
                                  }}
                                />
                                <div className="mb-2.5">
                                  {error && (
                                    <p>{error.message || "Errors!!!"}</p>
                                  )}
                                </div>
                              </>
                            )}
                          />
                        </div>
                      ) : (
                        <>
                          <Controller
                            control={control}
                            name={`statusCriteria.${index}.value`}
                            render={({ field: { value, onChange, name } }) => (
                              <NumericFormat
                                name={name}
                                className={`value_input ${
                                  props.isDisabledFunction ? "disabled" : ""
                                }`}
                                value={value}
                                onChange={(value) =>
                                  onChange(+value.target.value)
                                }
                                type="text"
                                allowNegative
                                disabled={props.isDisabledFunction}
                                placeholder={intl.formatMessage({
                                  id: "placeholder_value_input",
                                })}
                              />
                            )}
                          />
                        </>
                      )}
                      <div
                        onClick={() =>
                          deleteCriteria(
                            props.formMethods.getValues(
                              `statusCriteria.${index}.id`
                            )!,
                            index
                          )
                        }
                        className="delete_criteria cursor-pointer ml-2.5"
                      >
                        <TrashCan />
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>
      </ExpanderItem>
    </Expander>
  );
}

export default StatusSettings;
