import React from "react";
import "./Users.scss";
import { FormattedMessage, useIntl } from "react-intl";

function Users() {
  return (
    <div className="user">
      <h1>
        <FormattedMessage id="users" />
      </h1>
    </div>
  );
}

export default Users;
