import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import {
  NotificationState,
} from "../components/Notification/notificationTypes";
import { useIntl } from "react-intl";
import { authClient } from "../api/auth/AxiosInstanse";
import { UpdateTokens } from "../components/Login/LoginForm/LoginForm";
import {TokenModel, TokensDTO} from "../api/auth/apiClient";

type PrivateRouteType = {
  onChangeNotificationState: (state: NotificationState) => void;
};

const PrivateRoute = ({
  children,
  props,
}: {
  children: JSX.Element;
  props: PrivateRouteType;
}) => {
  const token = localStorage.getItem("token");
  const expiration = localStorage.getItem("expiration");
  const refreshToken = localStorage.getItem("refreshToken");
  const accessToken = localStorage.getItem("accessToken")
  const expirationRefreshToken = localStorage.getItem("expirationRefreshToken");
  const nowDate = Date.now();
  const location = useLocation();
  const intl = useIntl();

  function refresh(
    onChangeNotificationState: (state: NotificationState) => void
  ) {
    return authClient
      .refreshToken(
          new TokensDTO({
            accessToken: new TokenModel({
              token: token!,
              expiration: new Date(+expiration!*1000) }),
            refreshToken: new TokenModel({
              token: token!,
              expiration: new Date(+expirationRefreshToken!*1000)}),
          }))
      .then((result) => {
        return UpdateTokens(result, onChangeNotificationState, "token_updated_message", "token_message_expiration", "token_updated_message", "", intl);
      })
      .catch(() => {
        return false;
      });
  }

  if (!token && !refreshToken) {
    return <Navigate to="/login" replace state={{ from: location }} />;
  }

  if (nowDate > +expiration!) {
    /*    if (nowDate > +expirationRefreshToken!) {
      props.onChangeNotificationState({
        isShow: true,
        type: NotificationType.error,
        message: intl.formatMessage({id: "token_message_expiration"}),
      });
      return <Navigate to="/login" replace state={{from: location}}/>;
    } else {
      refresh(props.onChangeNotificationState).then(res => {
        if (!res) {return <Navigate to="/login" replace state={{from: location}}/>}}) //TODO не бросает на логин при ошибке
    }*/
    return <Navigate to="/login" replace state={{ from: location }} />;
  }
  return children;
};
export { PrivateRoute };
