import React from "react";
import Notification from "../Notification/Notification";
import { YMaps, Map, Placemark } from "@pbe/react-yandex-maps";
import { MapContainerProps } from "./MapContainerTypes";
import "./MapContainer.scss";

function MapContainer(props: MapContainerProps) {
  return (
    <>
      <YMaps query={{ load: "package.full" }}>
        <Map
          state={{
            center: [
              props.coordinates.latitudeCenter,
              props.coordinates.longitudeCenter,
            ],
            zoom: props.coordinates.zoom,
            controls: ["zoomControl"],
          }}
          width="100%"
          height="100%"
        >
          {props.carInfo
            .filter(
              (f) =>
                f.coordinates?.latitude !== undefined &&
                f.coordinates?.longitude !== undefined
            )
            .map((item) => (
              <Placemark
                key={item.id}
                geometry={[
                  item.coordinates?.latitude,
                  item.coordinates?.longitude,
                ]}
                options={{
                  iconLayout: "default#image",
                  iconImageSize: [40, 40],
                  iconImageHref: "./image/truckIcons/iconBus.svg",
                }}
              />
            ))}
        </Map>
      </YMaps>
    </>
  );
}
export default MapContainer;
