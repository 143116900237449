import { LanguageType } from "../LocaleSelector/LocaleSelectorType";
import { NotificationState } from "../Notification/notificationTypes";

export type LoginProps = {
  currentLocale: LanguageType;
  showNotification: NotificationState;
  changeCurrentLocale: (value: LanguageType) => void;
  onChangeNotificationState: (state: NotificationState) => void;
};
export enum EntryPageViewState {
  signUp,
  logIn,
  PWReset,
}
