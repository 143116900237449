import React, { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import "./FunctionsGroups.scss";
import { ReactComponent as Plus } from "../svg/Plus.svg";
import { FunctionDTO, FunctionGroup } from "../../../../api/auth/apiClient";
import { Gapped } from "@skbkontur/react-ui";
import { KebabGroup } from "./KebabGroup";
import ModalNotification from "../../../CreateTransport/Modal_notification/ModalNotification";
import { mapClient } from "../../../../api/auth/AxiosInstanse";
import {
  NotificationState,
  NotificationType,
} from "../../../Notification/notificationTypes";
import { ReactComponent as Approve } from "./svg/accept.svg";
import { ReactComponent as Cancel } from "./svg/cancellation.svg";

type FunctionsGroupsProps = {
  functionGroups: Array<FunctionGroup>;
  selectedRow: FunctionDTO;
  setSelectedRow: (row: FunctionDTO) => void;
  onShowModalGroups: (showModal: boolean) => void;
  setFunctionGroups: (functionGroups: FunctionGroup[]) => void;
  showNotification: NotificationState;
  onChangeNotificationState: (state: NotificationState) => void;
};

function FunctionsGroups(props: FunctionsGroupsProps) {
  const [selectedGroup, setSelectedGroup] = useState<FunctionGroup>();
  const [filteredGroup, setFilteredGroup] = useState<Array<FunctionGroup>>(
    props.functionGroups
  );
  const [showEditGroupName, setShowEditGroupName] = useState(false);
  const [showDeleteGroup, setShowDeleteGroup] = useState(false);
  const [groupIdForDelete, setGroupIdForDelete] = useState<number>();
  const [currentSearch, setCurrentSearch] = useState("");
  const [groupName, setGroupName] = useState<string>("");
  const [isCreateGroup, setIsCreateGroup] = useState(true);
  const intl = useIntl();
  function onChooseGroup(id: number) {
    const selectedGroup = props.functionGroups.find((group) => group.id === id);
    setSelectedGroup({ ...selectedGroup } as FunctionGroup);
  }
  function onSearchGroup(value: string) {
    setFilteredGroup(
      props.functionGroups.filter((g) =>
        g.groupName.toLowerCase().includes(value.toLowerCase())
      )
    );
  }
  function onShowDeleteModal() {
    setShowDeleteGroup(true);
  }
  async function updateOrCreateGroup() {
    let result;
    if (isCreateGroup) {
      try {
        result = await mapClient.createFunctionGroup(groupName);
        if (result?.succeeded) {
          props.onChangeNotificationState({
            isShow: true,
            type: NotificationType.info,
            message: intl.formatMessage({ id: "add_new_groups_success" }),
          });
        } else throw result.errors;
      } catch (error: any) {
        props.onChangeNotificationState({
          isShow: true,
          type: NotificationType.error,
          message: `${intl.formatMessage({ id: "add_new_groups_error" })}: ${
            error?.errors ? error.errors[0] : "Unknown error"
          }`,
        });
      }
    } else {
      try {
        result = await mapClient.updateFunctionGroup({
          id: selectedGroup?.id,
          groupName: groupName,
        } as FunctionGroup);
        if (result?.succeeded) {
          props.onChangeNotificationState({
            isShow: true,
            type: NotificationType.info,
            message: intl.formatMessage({ id: "reName_groups_success" }),
          });
        } else throw result.errors;
      } catch (error: any) {
        props.onChangeNotificationState({
          isShow: true,
          type: NotificationType.error,
          message: `${intl.formatMessage({ id: "reName_groups_error" })}: ${
            error?.errors ? error.errors[0] : "Unknown error"
          }`,
        });
      }
    }
    await updateFunctionGroup();
    setGroupName("");
    setShowEditGroupName(false);
  }
  async function handleKeyDown(event: React.KeyboardEvent<HTMLInputElement>) {
    if (event.key === "Enter") {
      await updateOrCreateGroup();
    }
  }
  async function deleteGroup(id: number) {
    setShowDeleteGroup(false);
    let result;
    try {
      result = await mapClient.deleteFunctionGroup(id);
      props.onChangeNotificationState({
        isShow: true,
        type: NotificationType.info,
        message: intl.formatMessage({ id: "delete_groups_success" }),
      });
    } catch (error: any) {
      props.onChangeNotificationState({
        isShow: true,
        type: NotificationType.error,
        message: `${intl.formatMessage({ id: "delete_groups_error" })}: ${
          error?.errors ? error.errors[0] : "Unknown error"
        }`,
      });
    }
    await updateFunctionGroup();
  }
  async function updateFunctionGroup() {
    let functionGroup;
    try {
      functionGroup = await mapClient.getFunctionGroups();
    } catch {}
    props.setFunctionGroups(functionGroup?.data ?? []);
    const sorted = [
      ...(functionGroup?.data ?? []).filter((g) => g.groupName === groupName),
      ...(functionGroup?.data ?? []).filter((g) => g.groupName !== groupName),
    ];
    setFilteredGroup(sorted);
  }

  return (
    <>
      {showDeleteGroup ? (
        <div>
          <ModalNotification
            modalQuestion={intl.formatMessage({
              id: "deleteGroupsQuestion",
            })}
            onYesModalActions={() => setShowDeleteGroup(false)}
            onNoModalActions={() => deleteGroup(groupIdForDelete!)}
          />
        </div>
      ) : null}
      <div className="modal_group">
        <div className="modal_group_container">
          <div className="modal_group_form">
            <div className="modal_group_title">
              <h3>
                <FormattedMessage id="group_title" />
              </h3>
              <img
                onClick={() => props.onShowModalGroups(false)}
                src="/image/btn-remove/btn-remove.svg"
                alt="Close form"
              />
            </div>
            <div className="group_body mt-5">
              <div className="group_search flex">
                <img
                  className="searchImage"
                  src="/image/search/search.svg"
                  alt="Search"
                />
                <input
                  type="text"
                  onChange={(event) => {
                    onSearchGroup(event.target.value);
                    setCurrentSearch(event.target.value);
                  }}
                  className="searchInputGroup"
                  placeholder={intl.formatMessage({
                    id: "placeholder_search_group",
                  })}
                  value={currentSearch}
                />
              </div>
              <div
                onClick={() => {
                  setIsCreateGroup(true);
                  setShowEditGroupName(true);
                }}
                className="group_button flex items-center mt-2 pl-2.5 cursor-pointer"
              >
                <Plus />
                <span className="group_button_title ml-2.5 pt-0.5">
                  <FormattedMessage id="add_new_group" />
                </span>
              </div>
              {showEditGroupName ? (
                <>
                  <div className="new_group">
                    <input
                      className="newGroupInput mr-3"
                      type="text"
                      placeholder="Введите название группы_"
                      value={groupName}
                      onChange={(event) => setGroupName(event.target.value)}
                      onKeyDown={handleKeyDown}
                    />
                    <div
                      className="mr-1 cursor-pointer"
                      onClick={updateOrCreateGroup}
                    >
                      <Approve />
                    </div>
                    <div
                      onClick={() => setShowEditGroupName(false)}
                      className="mr-2 cursor-pointer"
                    >
                      <Cancel />
                    </div>
                  </div>
                </>
              ) : null}
              <div className="group_list">
                {filteredGroup.map((g) => (
                  <li
                    className={`flex items-center justify-between ${
                      selectedGroup?.id === g.id ? "active" : ""
                    }`}
                    key={g.id}
                    onClick={() => onChooseGroup(g.id!)}
                  >
                    <span className="groupName">{g.groupName}</span>
                    <div>
                      <Gapped>
                        <KebabGroup
                          onShowDeleteGroup={() => {
                            setGroupIdForDelete(g.id);
                            onShowDeleteModal();
                          }}
                          onShowUpdateModal={() => {
                            setGroupName(g.groupName);
                            setIsCreateGroup(false);
                            setShowEditGroupName(true);
                          }}
                        />
                      </Gapped>
                    </div>
                  </li>
                ))}
              </div>
              <div className="mt-6 flex">
                <button
                  type="button"
                  className="back"
                  onClick={() => {
                    setShowEditGroupName(false);
                    props.onShowModalGroups(false);
                  }}
                >
                  <span>
                    <FormattedMessage id="button_back" />
                  </span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default FunctionsGroups;
