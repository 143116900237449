import React, { useState } from "react";
import "./ChangePassword.scss";
import { ReactComponent as BtnSve } from "./buttons/svg/Save.svg";
import { FormattedMessage, useIntl } from "react-intl";
import { SubmitHandler, useForm } from "react-hook-form";
import { ChangePasswordDTO, ResponseDTO } from "../../api/auth/apiClient";
import { mapClient } from "../../api/auth/AxiosInstanse";
import {
  NotificationState,
  NotificationType,
} from "../Notification/notificationTypes";
import ModalNotification from "./Modal/ModalNotification";

class ChangePasswordDTOWithConfirm extends ChangePasswordDTO {
  confirmPassword: string;
  constructor(confirmPassword: string) {
    super();
    this.confirmPassword = confirmPassword;
  }
}

type ChangePasswordTypeProps = {
  onChangeNotificationState: (state: NotificationState) => void;
  onShowChangePassword: (showModalChangePassword: boolean) => void;
};
function ChangePassword(props: ChangePasswordTypeProps) {
  const intl = useIntl();
  const [showModalNotification, setShowModalNotification] = useState(false);
  const {
    register,
    formState: { errors },
    handleSubmit,
    watch,
  } = useForm<ChangePasswordDTOWithConfirm>({ mode: "onChange" });
  const onSubmit: SubmitHandler<ChangePasswordDTO> = async (data) => {
    let result = new ResponseDTO();
    try {
      result = await mapClient.changePassword(new ChangePasswordDTO(data));
    } catch {}
    if (result.succeeded) {
      props.onChangeNotificationState({
        isShow: true,
        type: NotificationType.info,
        message: intl.formatMessage({ id: "change_current_password" }),
      });
    } else {
      props.onChangeNotificationState({
        isShow: true,
        type: NotificationType.error,
        message: intl.formatMessage({ id: "error_current_password" }),
      });
    }
    props.onShowChangePassword(false);
  };
  function onClose(showModalNotification: boolean) {
    setShowModalNotification(showModalNotification);
  }
  return (
    <>
      {showModalNotification ? (
        <div>
          <ModalNotification
            onClose={onClose}
            onShowChangePassword={props.onShowChangePassword}
          />
        </div>
      ) : null}
      <div className="overlay">
        <div className="changePasswordContainer">
          <div className="changePasswordForm">
            <div className="passwordFormHeader">
              <h2>
                <FormattedMessage id="change_password_title" />
              </h2>
              <img
                src="/image/btn-remove/btn-remove.svg"
                alt="Close form"
                onClick={() => onClose(true)}
              />
            </div>
            <div className="passwordFormDescription">
              <form
                autoComplete="off"
                onSubmit={handleSubmit(onSubmit)}
                className="formDescriptionBlock"
              >
                <label htmlFor="currentPassword">
                  <FormattedMessage id="current_password_title" />
                </label>
                <input
                  {...register("currentPassword", {
                    required: intl.formatMessage({ id: "input_required" }),
                  })}
                  type="password"
                  id="password"
                  placeholder={intl.formatMessage({
                    id: "placeholder_current_password",
                  })}
                />
                <div>
                  {errors?.currentPassword && (
                    <p>{errors?.currentPassword?.message || "Errors"}</p>
                  )}
                </div>
                <label htmlFor="newPassword">
                  <FormattedMessage id="new_password" />
                </label>
                <input
                  {...register("newPassword", {
                    required: intl.formatMessage({ id: "input_required" }),
                    pattern: {
                      value:
                        /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-+_]).{6,30}$/,
                      message: intl.formatMessage({ id: "validate_password" }),
                    },
                  })}
                  placeholder={intl.formatMessage({
                    id: "placeholder_new_password",
                  })}
                  type="password"
                  id="newPassword"
                />
                <div>
                  {errors?.newPassword && (
                    <p>{errors?.newPassword?.message || "Errors"}</p>
                  )}
                </div>
                <label htmlFor="confirmPassword">
                  <FormattedMessage id="confirm_password_title" />
                </label>
                <input
                  {...register("confirmPassword", {
                    required: intl.formatMessage({ id: "input_required" }),
                    validate: (value) => {
                      if (watch("newPassword") !== value) {
                        return intl.formatMessage({ id: "match_password" });
                      }
                    },
                  })}
                  placeholder={intl.formatMessage({
                    id: "placeholder_new_password",
                  })}
                  type="password"
                  id="confirmPassword"
                />
                <div>
                  {errors?.confirmPassword && (
                    <p>{errors?.confirmPassword?.message || "Errors"}</p>
                  )}
                </div>
                <div className="flex mt-5">
                  <div className="m-0">
                    <button type="submit" className="btn ml-auto">
                      <BtnSve />
                      <span className="ml-2.5">
                        <FormattedMessage id="save" />
                      </span>
                    </button>
                  </div>
                  <button
                    type="reset"
                    className="cancel"
                    onClick={() => onClose(true)}
                  >
                    <span>
                      <FormattedMessage id="cancel" />
                    </span>
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default ChangePassword;
