import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import "../Login/Login.scss";
import { SubmitHandler, useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import { ResetPasswordDTO, ResponseDTO } from "../../api/auth/apiClient";
import { authClient } from "../../api/auth/AxiosInstanse";
import {
  NotificationState,
  NotificationType,
} from "../Notification/notificationTypes";

class ResetPasswordDTOWithConfirm extends ResetPasswordDTO {
  confirmPassword: string;
  constructor(confirmPassword: string) {
    super();
    this.confirmPassword = confirmPassword;
  }
}

type ResetPasswordProps = {
  onChangeNotificationState: (state: NotificationState) => void;
};
function ResetPassword(props: ResetPasswordProps) {
  const navigate = useNavigate();
  const intl = useIntl();
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm<ResetPasswordDTOWithConfirm>();
  const location = useLocation();
  const resetToken = new URLSearchParams(location.search).get("ResetToken")!;
  const email = new URLSearchParams(location.search).get("Email")!;
  const onSubmit: SubmitHandler<ResetPasswordDTO> = async (data) => {
    let result = new ResponseDTO();
    try {
      result = await authClient.resetPassword(
        new ResetPasswordDTO({ password: data.password, resetToken, email })
      );
    } catch {}
    if (result.succeeded) {
      props.onChangeNotificationState({
        isShow: true,
        type: NotificationType.info,
        message: intl.formatMessage({ id: "reset_password" }),
      });
      navigate("/login");
    } else {
      props.onChangeNotificationState({
        isShow: true,
        type: NotificationType.error,
        message: intl.formatMessage({ id: "reset_password_error" }),
      });
    }
  };
  return (
    <div className="entryPage grid gap-10">
      <form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
        <div>
          <h2>
            <FormattedMessage id="create_new_password_title" />
          </h2>
        </div>
        <fieldset>
          <ul>
            <li>
              <label htmlFor="password">
                <FormattedMessage id="new_password" />:
              </label>
              <input
                {...register("password", {
                  required: intl.formatMessage({ id: "input_required" }),
                  pattern: {
                    value:
                      /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-+_]).{6,30}$/,
                    message: intl.formatMessage({ id: "validate_password" }),
                  },
                })}
                type="password"
                id="password"
              />
              <div className="mt-1">
                {errors?.password && (
                  <p>{errors?.password?.message || "Errors"}</p>
                )}
              </div>
            </li>
            <li>
              <label htmlFor="confirmPassword">
                <FormattedMessage id="confirm_password_title" />:
              </label>
              <input
                {...register("confirmPassword", {
                  required: intl.formatMessage({ id: "input_required" }),
                  validate: (value) => {
                    if (watch("password") !== value) {
                      return intl.formatMessage({ id: "match_password" });
                    }
                  },
                })}
                type="password"
                id="confirmPassword"
              />
              <div className="mt-1">
                {errors?.confirmPassword && (
                  <p>{errors?.confirmPassword?.message || "Errors"}</p>
                )}
              </div>
            </li>
          </ul>
        </fieldset>
        <button type="submit" className="mb-8">
          <FormattedMessage id="confirm" />
        </button>
      </form>
    </div>
  );
}

export default ResetPassword;
