import TrashIcon from "@skbkontur/react-icons/Trash";
import { Kebab, MenuItem, Toast } from "@skbkontur/react-ui";
import { ArrowParallelHorizontal } from "@skbkontur/react-icons";
import { FormattedMessage } from "react-intl";

let style = {};

type PropsType = {
  onShowDeleteFunctionModal: () => void;
};

export const Card = (props: PropsType) => (
  <div style={style}>
    <Kebab size={"medium"}>
      {/*<MenuItem icon={<ArrowParallelHorizontal />} onClick={() => Toast.push('Удалено')}>
                Переместить
            </MenuItem>*/}
      <MenuItem
        icon={<TrashIcon />}
        onClick={() => props.onShowDeleteFunctionModal()}
      >
        <FormattedMessage id="delete" />
      </MenuItem>
    </Kebab>
  </div>
);
