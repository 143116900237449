import "../../../styles/variables.scss";

export const customStyles = {
  control: (base: any) => ({
    ...base,
    height: 44,
    background: "$BgColor",
    cursor: "pointer",
  }),
  indicatorSeparator: (base: any) => ({
    ...base,
    display: "none",
  }),
  menu: (base: any) => ({
    ...base,
    width: 306,
    fontWeight: 400,
    fontSize: 14,
    color: "#000",
  }),
  option: (base: any) => ({
    ...base,
    cursor: "pointer",
  }),
  valueContainer: (base: any) => ({
    ...base,
    fontWeight: 400,
    fontSize: 16,
  }),
};
