import React from "react";
import "./AdmiinPage.scss";
import "../../index.scss";
import { FormattedMessage, useIntl } from "react-intl";

type AdminPageProps = {
  showAdminPage: boolean;
  onShowAdminPage: (showUsersPage: boolean) => void;
};

function AdminPage(props: AdminPageProps) {
  const intl = useIntl();
  return (
    <div>
      <h1>
        <FormattedMessage id="administration" />
      </h1>
    </div>
  );
}

export default AdminPage;
