import React, { useEffect, useRef, useState } from "react";
import TransportList from "../TransportList/TransportList";
import MapContainer from "../Map/MapContainer";
import "./Transport.scss";
import {
  CanIdentifierDTO,
  Coordinates,
  CriterionParameter,
  FunctionDTO,
  FunctionGroup,
  FunctionStatus,
  VehicleDTO,
  VehicleType,
} from "../../api/auth/apiClient";
import { useIntl } from "react-intl";
import TransportEdit from "../TransportEdit/TransportEdit";
import { MapCenterType, TransportTypeProps } from "./TransportType";
import { mapClient } from "../../api/auth/AxiosInstanse";
import { NotificationType } from "../Notification/notificationTypes";
import CreateTransport from "../CreateTransport/CreateTransport";
import {
  HttpTransportType,
  HubConnectionBuilder,
  HubConnectionState,
  JsonHubProtocol,
  LogLevel,
} from "@microsoft/signalr";

type messageDTO = {
  uvi: string;
  coordinatesDTO: {
    longitude: number;
    latitude: number;
  };
  engineHours: undefined;
  messageDate: Date;
  recordDate: Date;
  number: number;
};

function Transport(props: TransportTypeProps) {
  const [carInfo, setCarInfo] = useState<Array<VehicleDTO>>([]);
  const [searchTransport, setSearchTransport] = useState(carInfo);
  const [showTransportEditor, setShowTransportEditor] = useState(false);
  const intl = useIntl();
  const [mapCenter, setMapCenter] = useState<MapCenterType>({
    latitudeCenter: 55.4424,
    longitudeCenter: 37.3636,
    zoom: 9,
  });
  const [activeTransport, setActiveTransport] = useState<VehicleDTO>();
  const [showCreateTransport, setShowCreateTransport] = useState(false);
  const [transportActiveClick, setActiveClick] = useState(false);
  const [typeTransport, setTypeTransport] = useState<Array<VehicleType>>([]);
  const [functions, setFunctions] = useState<Array<FunctionDTO>>([]);
  const [filterFunctions, setFilterFunctions] = useState(functions);
  const [functionGroups, setFunctionGroups] = useState<Array<FunctionGroup>>(
    []
  );
  const [statusFunctions, setStatusFunctions] = useState<Array<FunctionStatus>>(
    []
  );
  const [criteriaParameters, setCriteriaParameters] = useState<
    Array<CriterionParameter>
  >([]);
  const [showTable, setShowTable] = useState(false);
  const [selectedRow, setSelectedRow] = useState<FunctionDTO>(
    {} as FunctionDTO
  );
  const [canIdentifiers, setCanIdentifiers] = useState<Array<CanIdentifierDTO>>(
    []
  );
  const [isStartCoordinatesUpdate, setStartCoordinatesUpdate] =
    useState<boolean>(false);
  /*  const carInfoRef = useRef(carInfo);*/

  /*Средние координаты транспорта*/
  function getAvgCoordinates(carInfo: Array<VehicleDTO>) {
    const avglongitude =
      carInfo
        .filter((c) => c.coordinates?.longitude !== undefined)
        .reduce((total, next) => total + +next.coordinates?.longitude!, 0) /
      carInfo.filter((c) => c.coordinates?.longitude !== undefined).length;
    const avglatitude =
      carInfo
        .filter((c) => c.coordinates?.latitude !== undefined)
        .reduce((total, next) => total + +next.coordinates?.latitude!, 0) /
      carInfo.filter((c) => c.coordinates?.longitude !== undefined).length;
    return {
      latitudeCenter: avglatitude,
      longitudeCenter: avglongitude,
      zoom: 5,
    };
  }
  /*Запросы на получение списка транспорта, получение типов машин, статусов функций, критериев*/
  async function getTransport() {
    try {
      const response = await mapClient.getVehicles();
      const sortedTransport = response.data!.sort((a, b) =>
        a.creationDate! > b.creationDate! ? -1 : 1
      );
      setCarInfo(sortedTransport);
      setSearchTransport(sortedTransport);
      setMapCenter(getAvgCoordinates(response.data!));
      setActiveTransport(
        response.data?.find((v) => v.id === activeTransport?.id)
      );
      let typeTransport;
      typeTransport = await mapClient.getVehicleTypes();
      setTypeTransport(typeTransport.data ?? []);

      let functionStatusResult;
      functionStatusResult = await mapClient.getFunctionStatuses();
      setStatusFunctions(functionStatusResult?.data ?? []);
      let criteriaParametersResult;
      criteriaParametersResult = await mapClient.getCriteriaParameters();
      setCriteriaParameters(criteriaParametersResult?.data ?? []);

      setStartCoordinatesUpdate(true);
    } catch (error) {
      props.onChangeNotificationState({
        isShow: true,
        type: NotificationType.error,
        message: intl.formatMessage({ id: "data_receiving_error" }),
      });
    }
  }
  useEffect(() => {
    getTransport();
  }, []);

  /*useEffect(() => {
    carInfoRef.current = carInfo;
  }, [carInfo]);*/

  useEffect(() => {
    if (isStartCoordinatesUpdate) {
      const protocol = new JsonHubProtocol();
      const connection = new HubConnectionBuilder()
        .withUrl("https://platform.sayoratec.com:160/coordinates", {
          accessTokenFactory: () => localStorage.getItem("token") ?? "",
          withCredentials: false,
          transport: HttpTransportType.WebSockets,
          logMessageContent: true,
          logger: LogLevel.Trace,
          skipNegotiation: false,
        })
        .withAutomaticReconnect()
        .withHubProtocol(protocol)
        .configureLogging(LogLevel.Information)
        .configureLogging(LogLevel.Trace)
        .build();
      try {
        connection
          .start()
          .then(() => {
            console.assert(connection.state === HubConnectionState.Connected);
            console.log("SignalR Connected.");
            connection.on("SendCoordinatesAsync", (message: messageDTO) => {
              const updatedCoordinates = carInfo.map((car) => {
                if (message.uvi === car.uvi) {
                  return {
                    ...car,
                    coordinates: {
                      longitude: message.coordinatesDTO.longitude,
                      latitude: message.coordinatesDTO.latitude,
                    },
                  };
                } else {
                  return car;
                }
              }) as VehicleDTO[];
              setCarInfo(updatedCoordinates);
              console.log(message);
            });
          })
          .catch((err) => {
            console.assert(
              connection.state === HubConnectionState.Disconnected
            );
            console.log(err);
          });
      } catch (error) {
        console.log(error);
      }
    }
  }, [isStartCoordinatesUpdate]);

  /*Запрос на получение списка функций по Imei транспорта*/
  async function getFunctionsList(
    uvi: string | undefined,
    id: string | undefined = undefined
  ) {
    let functionGroup;
    functionGroup = await mapClient.getFunctionGroups();
    setFunctionGroups(functionGroup.data ?? []);
    let canIdentifier;
    canIdentifier = await mapClient.getCanIdentifiers(uvi!);
    setCanIdentifiers(canIdentifier?.data ?? []);
    let response;
    if (uvi !== undefined && uvi !== null) {
      response = await mapClient.getFunctions(uvi);
      if (!response?.succeeded) {
        props.onChangeNotificationState({
          isShow: true,
          type: NotificationType.error,
          message: intl.formatMessage({ id: "data_functions_error" }),
        });
        throw response?.message;
      }
    }
    setFunctions(response?.data ?? []);
    setFilterFunctions(response?.data ?? []);
    const foundResult =
      response?.data!.find((r) => r.id === (selectedRow?.id ?? id)) ??
      ({} as FunctionDTO);
    setSelectedRow(foundResult);
  }

  /*Запрос на получение транспорта по id*/
  async function getTransportById(id: string) {
    let response = await mapClient.getVehicleById(id);
    setActiveTransport(response.data!);
    setCarInfo(
      carInfo.map((t) => {
        if (t.id === response.data!.id!) {
          return response.data!;
        }
        return t;
      })
    );
    setSearchTransport(
      searchTransport.map((t) => {
        if (t.id === response.data!.id!) {
          return response.data!;
        }
        return t;
      })
    );
    if (!response?.succeeded) {
      props.onChangeNotificationState({
        isShow: true,
        type: NotificationType.error,
        message: intl.formatMessage({ id: "transport_error_byId" }),
      });
      throw response?.message;
    }
  }
  async function onChooseTransport(id: string) {
    if (!showTable) {
      const selectedTransport = carInfo.find((car) => car.id === id)!;
      setActiveTransport({ ...selectedTransport } as VehicleDTO);
      try {
        await getFunctionsList(selectedTransport.uvi);
      } catch {
        props.onChangeNotificationState({
          isShow: true,
          type: NotificationType.error,
          message: intl.formatMessage({ id: "data_functions_error" }),
        });
      }
    } else {
      props.onChangeNotificationState({
        isShow: true,
        type: NotificationType.error,
        message: intl.formatMessage({ id: "error_clickTransport" }),
      });
    }
  }
  function onShowTransportEditorList(showTransportEditor: boolean) {
    setShowTransportEditor(showTransportEditor);
  }
  function onCloseModalActions() {
    onShowCreateTransport(false);
    onClickButtonAddNewTransport(false);
  }
  function onShowCreateTransport(showCreateTransport: boolean) {
    setShowCreateTransport(showCreateTransport);
  }
  function onClickButtonAddNewTransport(transportActiveClick: boolean) {
    setActiveClick(transportActiveClick);
  }
  return (
    <div className="transportMain grid">
      <div className={props.showTransportList ? "z-10" : "-z-10 "}>
        {/*Список транспорта*/}
        {props.showTransportList ? (
          <TransportList
            carInfo={carInfo}
            onShowTransportList={props.onShowTransportList}
            setMapCenter={setMapCenter}
            onChooseTransport={onChooseTransport}
            onShowTransportEditorList={onShowTransportEditorList}
            onShowCreateTransport={onShowCreateTransport}
            onClickButtonAddNewTransport={onClickButtonAddNewTransport}
            transportActiveClick={transportActiveClick}
            activeTransport={activeTransport!}
            getTransport={getTransport}
            searchTransport={searchTransport}
            setSearchTransport={setSearchTransport}
            showNotification={props.showNotification}
            onChangeNotificationState={props.onChangeNotificationState}
          />
        ) : null}
      </div>
      {/*Модальное окно Создание транспорта*/}
      <div className="mapContainer">
        {showCreateTransport ? (
          <div>
            <CreateTransport
              onShowCreateTransport={onShowCreateTransport}
              onClickButtonAddNewTransport={onClickButtonAddNewTransport}
              getTransport={getTransport}
              onChangeNotificationState={props.onChangeNotificationState}
              onCloseModalActions={onCloseModalActions}
              showNotification={props.showNotification}
            />
          </div>
        ) : null}
        {/*Редактирование выбранного транспорта*/}
        {showTransportEditor ? (
          <TransportEdit
            carInfo={carInfo}
            coordinates={mapCenter}
            functions={functions}
            filterFunctions={filterFunctions}
            showNotification={props.showNotification}
            onChangeNotificationState={props.onChangeNotificationState}
            activeTransport={activeTransport!}
            setActiveTransport={setActiveTransport}
            onShowTransportEditorList={onShowTransportEditorList}
            typeTransport={typeTransport}
            setFilterFunctions={setFilterFunctions}
            getFunctionsList={getFunctionsList}
            getTransport={getTransport}
            getTransportById={getTransportById}
            functionGroups={functionGroups}
            setFunctionGroups={setFunctionGroups}
            setShowTable={setShowTable}
            showTable={showTable}
            statusFunctions={statusFunctions}
            criteriaParameters={criteriaParameters}
            selectedRow={selectedRow}
            setSelectedRow={setSelectedRow}
            canIdentifiers={canIdentifiers}
            setCanIdentifiers={setCanIdentifiers}
          />
        ) : null}
        {/*Карта*/}
        {!showTransportEditor ? (
          <MapContainer carInfo={carInfo} coordinates={mapCenter} />
        ) : null}
      </div>
    </div>
  );
}
export default Transport;
