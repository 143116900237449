import React from "react";
import "./ArchivedTransport.scss";
import { VehicleDTO } from "../../../api/auth/apiClient";
import { FormattedMessage } from "react-intl";

type ArchivedTransportProps = {
  archivedVehicle: VehicleDTO;
  restoreArchivedVehicle: () => void;
  rewriteArchivedVehicle: () => void;
};
function ArchivedTransport(props: ArchivedTransportProps) {
  return (
    <div className="archived_transport">
      <div className="archived_transport_container">
        <div className="archived_modal_form">
          <div className="modal_form_title">
            <h2>
              <FormattedMessage id="archived_transport_title" />
            </h2>
          </div>
          <div className="modal_form_body mt-4">
            <div className="pb-2">
              <span>
                <FormattedMessage id="nameTransport" />
                <p className="pt-1 text-[#16a34a]">
                  {props.archivedVehicle.name}
                </p>
              </span>
            </div>
            <div className="pb-3">
              <span>
                <FormattedMessage id="transportImei" />
                <p className="pt-1 text-[#16a34a]">
                  {props.archivedVehicle.module?.imei}
                </p>
              </span>
            </div>
            <div className="pb-3">
              <span>
                <FormattedMessage id="simNumber" />
                <p className="pt-2 text-[#16a34a]">
                  {props.archivedVehicle.module?.simPhoneNumber}
                </p>
              </span>
            </div>
            <div className="pb-3">
              <span>
                <FormattedMessage id="simPin" />
                <p className="pt-1 text-[#16a34a]">
                  {props.archivedVehicle.module?.simPin}
                </p>
              </span>
            </div>
          </div>
          <div className="mt-6">
            <p className="from_subtitle">
              <FormattedMessage id="archivedTransportQuestion" />
            </p>
          </div>
          <div className="modal_buttons flex mt-5">
            <div className="m-0">
              <button
                onClick={() => props.restoreArchivedVehicle()}
                className="btn"
              >
                <span>
                  <FormattedMessage id="restore" />
                </span>
              </button>
            </div>
            <button
              onClick={() => props.rewriteArchivedVehicle()}
              className="btn ml-auto"
            >
              <span>
                <FormattedMessage id="overwrite" />
              </span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ArchivedTransport;
