import { DataOrder, DataSigned, DataType } from "../../../api/auth/apiClient";

export const optionsDataType = [
  { label: "bit", value: DataType._1 },
  { label: "byte", value: DataType._2 },
  { label: "2-Byte", value: DataType._3 },
];

export const DataCollectionPeriod = [
  {label: "1 секунда", value: 1},
  { label: "30 секунд", value: 30 },
  { label: "1 минуту", value: 60 },
  { label: "10 минут", value: 600 },
  { label: "30 минут", value: 1800 },
  { label: "1 час", value: 3600 },
  { label: "4 часа", value: 1400 },
  { label: "12 часов", value: 43200},
  { label: "24 часа", value: 86400 },
];


export const DataSendingPeriod = [
  {label: "1 секунда", value: 1},
  { label: "30 секунд", value: 30 },
  { label: "1 минуту", value: 60 },
  { label: "10 минут", value: 600 },
  { label: "30 минут", value: 1800 },
  { label: "1 час", value: 3600 },
  { label: "4 часа", value: 1400 },
  { label: "12 часов", value: 43200},
  { label: "24 часа", value: 86400 },
];


export const optionsCanType = [
  { label: "Standard", value: 1 },
  { label: "J1939", value: 2 },

];

export const optionsDataByte = [
  { label: "0", value: 0 },
  { label: "1", value: 1 },
  { label: "2", value: 2 },
  { label: "3", value: 3 },
  { label: "4", value: 4 },
  { label: "5", value: 5 },
  { label: "6", value: 6 },
  { label: "7", value: 7 },
];

export const optionsDataBit = [
  { label: "0", value: 0 },
  { label: "1", value: 1 },
  { label: "2", value: 2 },
  { label: "3", value: 3 },
  { label: "4", value: 4 },
  { label: "5", value: 5 },
  { label: "6", value: 6 },
  { label: "7", value: 7 },
];

export const optionsDataSigned = [
  { label: "signed", value: DataSigned._1 },
  { label: "unsigned", value: DataSigned._2 },
];

export const optionsDataOrder = [
  { label: "L...H", value: DataOrder._1 },
  { label: "H...L", value: DataOrder._2 },
];
