import axios from "axios";
import {Client, TokenModel, TokensDTO} from "./apiClient";



let token = localStorage.getItem("token");
/*const expiration = localStorage.getItem("expiration");
const refreshToken = localStorage.getItem("refreshToken");
const expirationRefreshToken = localStorage.getItem("expirationRefreshToken");*/
export const instance = axios.create({
  withCredentials: true,
  headers: {
    Authorization: "Bearer " + token,
  },
});
instance.interceptors.request.use(function (config) {
  const token = localStorage.getItem("token");
  config.headers.Authorization = token ? `Bearer ${token}` : "";
  return config;
});

/*instance.interceptors.response.use(
    (config) => {
      return config;
    },
    async (error) => {
      const originalRequest = {...error.cofig};
      originalRequest._isRetry = true;
      if(error.response.status === 401 && error.config && !error.config._isRetry) {
        try {
            const responce = await authClient.refreshToken( new TokensDTO({
              accessToken: new TokenModel({
                token: token!,
                expiration: new Date(+expiration!*1000) }),
              refreshToken: new TokenModel({
                token: token!,
                expiration: new Date(+expirationRefreshToken!*1000)}),
            }))
            localStorage.setItem("token", responce.tokens.);
        } catch{}
      }
    }
)*/

export const authClient = new Client(process.env.REACT_APP_BASE_URL);
export const mapClient = new Client(process.env.REACT_APP_BASE_URL, instance);
