export const customStyles = {
  control: (base: any) => ({
    ...base,

    height: 44,
    border: "$Border2",
    borderRadius: 4,
    background: "$BgColor",
    cursor: "pointer",
  }),
  indicatorSeparator: (base: any) => ({
    ...base,
    display: "none",
  }),
  menu: (base: any) => ({
    ...base,
    width: 306,
  }),
};