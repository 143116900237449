import React from "react";
import Select from "react-select";
import { customStyles } from "./customStyles";
import { languages } from "./languages";
import { LocaleSelectProps } from "./LocaleSelectorType";

const LocaleSelector = (props: LocaleSelectProps) => {
  return (
    <Select
      value={props.currentLocale}
      options={languages}
      isSearchable={false}
      styles={customStyles}
      onChange={(e) => props.changeCurrentLocale(e!)}
      formatOptionLabel={(lang) => (
        <div>
          <img
            className="inline-grid p-1 py-1"
            src={lang.image}
            alt="CountryImage"
          />
          <span className="font-normal text-base text-[#1A141F] pt-0.5">
            {lang.label}
          </span>
        </div>
      )}
    />
  );
};
export default LocaleSelector;
